export default {
    methods: {
        /**
         * Translate the given key.
         */
        __(key, replace = {}) {
            let translation = this.$page.props.language[key]
                ? this.$page.props.language[key]
                : key;

            Object.keys(replace).forEach(function (key) {
                translation = translation.replace(':' + key, replace[key]);
            });
            return document.createTextNode(translation).textContent;
        },

        /**
         * Get the current plans with ids.
         * @returns {*}
         */
        getPlans() {
            return {
                basic: [
                    '25138',
                    '23635',
                    '34007',
                    '785874',
                    '785870',
                    '785874',
                ],
                standard: ['785873', '785875'],
                professional: [
                    '24441',
                    '24442',
                    '760419',
                    '760420',
                    '757286',
                    '757287',
                ],
            };
        },

        /**
         * Get the current plan ids.
         * @param plan
         * @returns {*}
         */
        getPlanIds(plan) {
            let plans = this.getPlans();
            return plans[plan];
        },

        /**
         * Get the current plan name.
         * @param planId
         * @returns {*}
         */
        getPlanNames(planId) {
            let planNames = {
                25138: 'Basic',
                23635: 'Basic',
                34007: 'Basic',
                785874: 'Basic',
                785870: 'Basic',
                785873: 'Standard',
                785875: 'Standard',
                24441: 'Professional',
                24442: 'Professional',
                760419: 'Professional',
                760420: 'Professional',
                757286: 'Professional',
                757287: 'Professional',
            };

            return planNames[planId];
        },

        /**
         * Get the paddle products.
         * @returns {*}
         */
        getPaddleProducts(type = null) {
            let products = 'billing' == paddleType ? this.getPaddleBillingProducts() : this.getPaddleClassicProducts();

            if (type) {
                return products[type];
            }

            return products;
        },

        getPaddleBillingProducts() {
            return {
                'character-credits': {
                    small: import.meta.env.VITE_SPARK_BILLING_CHARACTER_CREDITS_SMALL,
                    medium: import.meta.env.VITE_SPARK_BILLING_CHARACTER_CREDITS_MEDIUM,
                    large: import.meta.env.VITE_SPARK_BILLING_CHARACTER_CREDITS_LARGE,
                },
                'keyword-research-credits': {
                    small: import.meta.env.VITE_SPARK_BILLING_KEYWORD_RESEARCH_CREDITS_SMALL,
                    medium: import.meta.env.VITE_SPARK_BILLING_KEYWORD_RESEARCH_CREDITS_MEDIUM,
                    large: import.meta.env.VITE_SPARK_BILLING_KEYWORD_RESEARCH_CREDITS_LARGE,
                },
                'serp-beater-credits': {
                    small: import.meta.env.VITE_SPARK_BILLING_SERP_BEATER_CREDITS_SMALL,
                    medium: import.meta.env.VITE_SPARK_BILLING_SERP_BEATER_CREDITS_MEDIUM,
                    large: import.meta.env.VITE_SPARK_BILLING_SERP_BEATER_CREDITS_LARGE,
                },
                'image-credits': {
                    small: import.meta.env.VITE_SPARK_BILLING_IMAGE_CREDITS_SMALL,
                    medium: import.meta.env.VITE_SPARK_BILLING_IMAGE_CREDITS_MEDIUM,
                    large: import.meta.env.VITE_SPARK_BILLING_IMAGE_CREDITS_LARGE,
                },
            };
        },


        getPaddleClassicProducts() {
            return {
                'character-credits': {
                    small: parseInt(
                        import.meta.env.VITE_SPARK_CHARACTER_CREDITS_SMALL
                    ),
                    medium: parseInt(
                        import.meta.env.VITE_SPARK_CHARACTER_CREDITS_MEDIUM
                    ),
                    large: parseInt(
                        import.meta.env.VITE_SPARK_CHARACTER_CREDITS_LARGE
                    ),
                },
                'keyword-research-credits': {
                    small: parseInt(
                        import.meta.env.VITE_SPARK_KEYWORD_RESEARCH_CREDITS_SMALL
                    ),
                    medium: parseInt(
                        import.meta.env.VITE_SPARK_KEYWORD_RESEARCH_CREDITS_MEDIUM
                    ),
                    large: parseInt(
                        import.meta.env.VITE_SPARK_KEYWORD_RESEARCH_CREDITS_LARGE
                    ),
                },
                'serp-beater-credits': {
                    small: parseInt(
                        import.meta.env.VITE_SPARK_SERP_BEATER_CREDITS_SMALL
                    ),
                    medium: parseInt(
                        import.meta.env.VITE_SPARK_SERP_BEATER_CREDITS_MEDIUM
                    ),
                    large: parseInt(
                        import.meta.env.VITE_SPARK_SERP_BEATER_CREDITS_LARGE
                    ),
                },
                'image-credits': {
                    small: parseInt(import.meta.env.VITE_SPARK_IMAGE_CREDITS_SMALL),
                    medium: parseInt(
                        import.meta.env.VITE_SPARK_IMAGE_CREDITS_MEDIUM
                    ),
                    large: parseInt(
                        import.meta.env.VITE_SPARK_IMAGE_CREDITS_LARGE
                    ),
                },
            };
        },

        /**
         * Get the specific paddle product ID.
         * @param type
         * @param size
         * @returns {*}
         */
        getPaddleProductIds(type, size) {
            let products = this.getPaddleProducts();

            if (products[type] && products[type][size]) {
                return products[type][size];
            } else {
                return null; // or throw an error or any other default value
            }
        },

        /**
         * Get the current plan name.
         * @param planId
         * @returns {*}
         */
        getPaddleProductNames(planId) {
            let planNames = {
                [import.meta.env.VITE_SPARK_KEYWORD_RESEARCH_CREDITS_SMALL]: '10',
                [import.meta.env.VITE_SPARK_KEYWORD_RESEARCH_CREDITS_MEDIUM]: '50',
                [import.meta.env.VITE_SPARK_KEYWORD_RESEARCH_CREDITS_LARGE]: '100',
            };

            return planNames[planId];
        },
    },
};
